/**
 * @file
 * A JavaScript file for the theme.
 *
 * In order for this JavaScript to be loaded on pages, see the instructions in
 * the README.txt next to this file.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - https://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
(function ($, Drupal, window, document) {

    // To understand behaviors, see https://drupal.org/node/756722#behaviors
    Drupal.behaviors.basic = {
        attach: function (context, settings) {
            // Comentado ya que está deprecado en JQUERY 1.8
            //$(window).load(function () {
            //  // Execute code once the window is fully loaded.
            //});

            $(window).resize(function () {
                // Execute code when the window is resized.
            });

            $(window).scroll(function () {
                // Execute code when the window scrolls.
            });

            var resize_timeout = 0;

            var refreshHeader = function () {
                clearTimeout(resize_timeout);
                resize_timeout = setTimeout(function () {
                    var $screen_sm = 768;
                    var $width = $(window).width();
                    if ($width < $screen_sm) {
                        var $header = $("#header");
                        $header.addClass('headerFixed');
                        $("#wrapper").css('margin-top', $header.height());
                    }
                    else {
                        $("#header").removeClass('headerFixed');
                        $("#wrapper").css('margin-top', '0');
                    }
                }, 150);
            };

            $(document).ready(function () {

                $(window).resize(function () {
                    refreshHeader();
                });

                refreshHeader();

                var $block_menu = $('#block-menu-block-3');
                $block_menu.find('ul.menu li:has(ul)').once('proccess-items', function () {
                    $(this).addClass('menu-inner');
                });

                $('#more-menu').once('process-button', function () {
                    $(this).click(function (e) {
                        e.preventDefault();
                        $block_menu.find('ul.menu').toggleClass('menu-expanded');
                    });
                });
            });
        }
    };

}(jQuery, Drupal, this, this.document));
